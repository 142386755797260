.proyect-view {
    width: 100%; height: 100%;
}
.proyect-view .proyect-header {
    
    background-color:  black;
    padding: 0 0 1rem 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    filter: drop-shadow( 5px 5px 10px #000 );
    
}
.proyect-view .proyect-header .header {
    padding: 2rem 0.5rem 2rem;
    width: 100%;
    display: flex;
    justify-content:space-around;
    margin-bottom: 1rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    align-items: center;
    
}
.proyect-view .proyect-header .header .img-content {
    box-shadow: 10px 5px 5px black;
  
    padding: 0; margin: 0; 

}
.proyect-view .proyect-header .header .proyect-info {

    padding: 0 1rem; margin: 0; 
} 
.proyect-view .proyect-header .header .proyect-info h2{
    text-shadow: 3px 3px 4px #0e0e0e;
}
.proyect-view .proyect-header .header .proyect-info h3{
    text-shadow: 3px 3px 4px #0e0e0e;
}
.proyect-view .proyect-header .header .proyect-info h4{
    text-shadow: 3px 3px 4px #0e0e0e;
}
.proyect-view .proyect-header .body {
    padding: 1rem;
    color: white;
    
    text-align: center;
}

.proyect-view .proyect-header .body .contact-social {
    margin-bottom: 1.5rem;
}
.proyect-view .proyect-header .body .contact-social a {

    margin: 0 0.8rem;
}
.proyect-view .proyect-header .body .contact-social a img {
    max-width: 2.2rem;

}
.proyect-view .proyect-header .body h2 {
    
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 1rem;
}
.proyect-view .proyect-header .body .proyect-members {
    display: flex; justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 1.2rem;
}
.proyect-view .proyect-header .body .proyect-members div{
    margin: 0 0.5rem;
    text-align: center;
    align-items: center;
    justify-content: center;

}
.proyect-view .proyect-header .body .proyect-members div .roll {
    display: flex; flex-wrap: wrap;
}
.proyect-view .proyect-header .body .proyect-members h2 {
    font-size: 16px;
    font-weight: 400;
}
.proyect-view .proyect-header .body .proyect-members h3 {
    font-size: 14px;
    font-weight: 300;
}

.proyect-view .proyect-header .body .proyect-bio h3{
    font-size: 16px;
    font-weight: 400;
}


.proyect-view .proyect-header .img-content {
    width: 26vh; height: 26vh;
    margin: 0 1rem;
    background-position: center;
    background-size: cover;
}
.proyect-view .proyect-header .proyect-info {
    color: white;
    display: flex; flex-direction: column;
    justify-content: center;
    
}
.proyect-view .proyect-header .proyect-info h2 {
    color: white;
    font-size: 24px;
    font-size: 500;
    font-family: 'Poppins';
}
.proyect-view .proyect-header .proyect-info h3 {
    color: white;
    font-size: 18px;
    font-weight: 200;
    font-family: 'Poppins';
}
.proyect-view .proyect-header .proyect-info h4 {
    color: white;
    font-size: 16px;
    font-size: 200;
    font-family: 'Poppins';
}



.proyect-view .proyect-body {
    width: 100%; height: 100%;
    padding: 1rem 0.5rem;
}

.proyect-view .proyect-body .album-container h2 {
    color: white;
    text-align: center;
    background-color: rgb(0, 0, 0);
    padding-top: 1rem;
    font-size: 18px;
    font-weight: 400;
    margin: 0;
}
.proyect-view .proyect-body .album-container .album-members {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%; 
    padding-bottom: 1rem;
    background-color: rgb(0, 0, 0);
    border-radius: 0.2rem;

    margin-bottom: 1.5rem;
}
.proyect-view .proyect-body div .album-members .member{

    text-align: center;
    margin: 0 1.5rem;
}
.proyect-view .proyect-body div .album-members .member .roll {
    
    width: 100%;
    display: flex;
    justify-content: center;
    color: white;
    
}
.proyect-view .proyect-body div .album-members .member h2 {
    font-size: 16px;
    color: white;
    font-weight: 500;
    margin-bottom: 0.2rem;
}
.proyect-view .proyect-body div .album-members .member .roll h3 {
    
    font-weight: 400;
    font-size: 14px;
}

#root > div > div > div.proyect-view > div.proyect-body > div {
    margin: 2rem 0.5rem;
}
#root > div > div > div.proyect-view > div.proyect-body > div > div > a {
    font-size: 25px;
}
@media only screen and (min-width: 800px) and (max-width: 1800px) {


    
    .proyect-view .proyect-body .albums-container {
        padding: 0 8rem;
    }

    .proyect-view .proyect-header .header {
        padding: 2rem 6rem ;
    }


} 