.audio-player {
    position: fixed;
    width: 100%;
    padding: 0 0.5rem;
    bottom: 0;
    z-index: 200;
}
.audio-player .rhap_container  {
    background-color: rgb(0, 0, 0);
    border-radius: 0.2rem;
}
.audio-player .rhap_container .rhap_header  {
    color: white;
    padding-left: 2rem;
}
.audio-player .rhap_container .rhap_header .header h2{
    color: rgb(255, 255, 255);
    font-size: 20px;
    font-weight: 500;
    margin: 0;
}
.audio-player .rhap_container .rhap_header .header h3{
    color: rgba(255, 229, 173, 0.932);
    font-size: 16px;
    font-weight: 100;
    margin: 0;
}
.audio-player .player .rhap_main-controls button{

    color: white;
}

.audio-player .player .rhap_additional-controls{
    color: white;
}

@media only screen and (min-width: 1023px) and (max-width: 1440px) {
 

    .audio-player .rhap_container .rhap_header .header h2{
        color: rgb(255, 255, 255);
        font-size: 16px;
        font-weight: 500;
        margin: 0;
    }
    .audio-player .rhap_container .rhap_header .header h3{
        color: rgba(255, 229, 173, 0.932);
        font-size: 12px;
        font-weight: 100;
        margin: 0;
    }
    
}