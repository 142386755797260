.proyects-view {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.proyect-link {
  width: 100%;
  height: 100%;
  color: rgb(17, 24, 23);
  list-style: none;
  text-decoration: none;
}

.proyect-card {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 12rem;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
}

.animated-gradient {
  animation: animateBg 14s linear infinite;
  background-image: linear-gradient(90deg, #5bd7be, #48bccb, #5bd7be, #48bccb);
  background-size: 300% 100%;
}

@keyframes animateBg {
  0% { background-position: 100% 0%; }
  100% { background-position: 0% 0%; }
}

.card-img-container {
  width: 8.5rem;
  height: 8.5rem;
  background-size: contain; /* Cambiado de 'cover' a 'contain' */
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 0.5rem;
  margin-right: 1rem;
  background-color: black;
}

.card-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.card-info h2 {
  font-size: 1rem;
  font-weight: 200;
  color: #000000;
  margin: 0;
}
#root > div > div > div.article > div.home-article > div > a > div > div.card-content > div > h2:nth-child(2){
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 0.9;
  margin-bottom: 0.5rem;
  color: black;
}
.album-card-content .card-info {
  padding: 0.7rem;
}
#root > div > div > div.article > div.home-article > div > a > div > div.card-content > a {
  line-height: 0.9;
  font-size: 1rem;
  color: rgb(59, 43, 97);
}
#root > div > div > div.proyect-view > div.proyect-body > div > div > div > div.card-content.album-card-content > div > h2 {
  font-weight: 600;
  font-size: 1.2rem;
}

.card-content .card-icon {
  color: black;
  border: 2px solid rgb(179, 124, 87);
  border-radius: 50%;
  font-size: 3rem;
  padding: 0.5rem;
}

@media only screen and (min-width: 400px) and (max-width: 430px) {
  .card-content .card-icon {
      right: -16vh;
  }
}

@media only screen and (min-width: 765px) and (max-width: 1000px) {
  .proyect-card {
      padding: 1rem;
      height: 15rem;
  }

  .card-img-container {
      width: 12rem;
      height: 12rem;
  }

  .card-content {
      width: 70%;
  }

  .card-info {
      position: relative;
      top: 2rem;
  }

  .card-info h2:nth-child(1n) { font-size: 22px; }
  .card-info h2:nth-child(2n) { font-size: 32px; }
  .card-info h2:nth-child(3n) { font-size: 22px; }
  .card-info h2:nth-child(4n) { font-size: 26px; }
}

@media only screen and (min-width: 1023px) and (max-width: 1440px) {
  .proyects-view {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
  }

  .proyect-link {
      width: 40%;
      margin: 0 0.5rem 1rem;
  }

  .proyect-card {
      padding: 1rem;
      width: 100%;
  }

  .card-content .card-icon {
      font-size: 3.2rem;
      margin: 0.5rem;
      right: -12rem;
  }
}
