.home-view {
    width: 100%;
}


.home-header{
    width: 100%; height: 40vh;
    background-color: rgb(94, 94, 94);
}
.carousel-header {
    width: 100%; height: 100%;
}
.carousel-header .carousel-inner {
    width: 100%; height: 100%;
}
.carousel-header .carousel-item {
    width: 100%; height: 100%;
  
}
.carousel-header  .carousel-item .img-container {

    width: 100%; height: 100%;
    background-size: cover;
    background-position: center;
}



.home-header .header-shadow-1{
    width: 40%; height: 40vh;
    position: absolute;
    top: 0;
    z-index: 10;
    background: rgb(0,0,0);
    background: linear-gradient(87deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 0%, rgba(0,0,0,0.8319088319088319) 26%, rgba(0,0,0,0.5598290598290598) 47%, rgba(0,0,0,0.34045584045584043) 56%, rgba(0,0,0,0) 73%);
}
.home-header .header-shadow-2{
    width: 100%; height: 8vh;
    position: absolute;
    top: 32vh;
    z-index: 10;
    background: rgb(0,0,0);
    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 0%, rgba(0,0,0,0.8319088319088319) 26%, rgba(0,0,0,0.5598290598290598) 47%, rgba(0,0,0,0.34045584045584043) 56%, rgba(0,0,0,0) 73%);}
.home-header h1:nth-child(1n) {
    position: absolute;
    top: 0;
    padding-top: 1rem;
    padding-right: 1rem;
    text-align: center;
    margin: 0;
    z-index: 50;
    

    
    font-size: 32px;
    font-weight: 700;

    height: 40vh;
    writing-mode: vertical-lr;
    transform: rotate(180deg);
    font-family: 'Poppins', sans-serif;
    
    letter-spacing: 3px;
    color: rgb(226, 255, 252);
}
.home-header h1:nth-child(2n) {
    position: absolute;
    top: 0;
    padding-top: 1rem;
    padding-right: 3rem;
    text-align: center;
    margin: 0;
    z-index: 50;

    
    font-size: 32px;
    font-weight: 700;

    height: 40vh;
    writing-mode: vertical-lr;
    transform: rotate(180deg);
    font-family: 'Poppins', sans-serif;
    
    letter-spacing: 3px;
    color: rgb(226, 255, 252);
}

.home-header h2{
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 1.5px;
    position: absolute;
    top: 35vh;
    right: 1rem;
    color: rgb(255, 255, 255);
    z-index: 50;
}


.home-nav{
    width: 100vw; height: 4rem;
    background-color: rgb(5, 5, 5);
    border: 0.5px rgba(140, 243, 204, 0.281) solid;
    border-radius: 0.5rem;
    overflow: hidden;
    overflow-x: scroll;
    z-index: 20;
    position:sticky;
    top: 0;
}
.home-nav ul {
    width: 100%;
    max-width: 120%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0; margin: 0 0.7rem;
     height: 100%;
}
.home-nav ul li {
    list-style: none;
    padding: 0 0.5rem;
}
.home-nav ul li button {
    letter-spacing: 1px;
    color: white;
    border: none;
    background-color: inherit;
}
.home-nav ul li .active {
    color: rgb(98, 248, 233);
}

.proyect-nav {
    width: 100%; height: 4rem;
    background-color: rgb(5, 5, 5);
    border: 0.5px rgba(140, 243, 204, 0.281) solid;
    border-radius: 0.5rem;
    z-index: 20;
    position:sticky;
    top: 0;
}
.proyect-nav ul {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0; margin: 0;
    height: 100%;
  
}
.proyect-nav ul li {
    list-style: none;
    padding: 0 0.5rem;
}
.proyect-nav ul li button {
    letter-spacing: 1px;
    color: white;
    border: none;
    background-color: inherit;
}
.proyect-nav ul li .active {
    color: rgb(98, 248, 233);
}

.home-article{
    margin: 0; padding: 0;
    padding:  1rem 0.5rem;
    width: 100%; 
    background-color: rgb(15, 21, 22);
}

@media only screen and (max-width: 770px) {
    .home-header {
        height: 40vh;
    }
    .home-header .header-shadow-1{
        width: 40%; height: 40vh;
    }
    .home-header .header-shadow-2 {
        width: 100%; height: 8vh;
        top: 32vh;
        margin-left: auto;
        margin-right: auto;
    }
    .home-header h1:nth-child(1n) {
        height: 40vh;
        font-size: 50px;
    }
    .home-header h1:nth-child(2n){
        padding-right: 4rem;
    } 
    .home-header h2 {
        top: 36vh;
    }

    
}
@media only screen and (min-width: 800px) and (max-width: 1000px) {
   
    .home-header {
        height: 22vh;
        width: 100%;
        
    }
    .home-header .header-shadow-1{
        width: 40%; height: 22vh;
    }
    .home-header .header-shadow-2 {
        width: 100%; height: 5vh;
        top: 17vh;
        margin-left: auto;
        margin-right: auto;
    }

    .home-header h1:nth-child(1n) {
        font-size: 45px;
        padding-right: 1.5rem;
        height: 22vh;
    }
    .home-header h1:nth-child(2n){
        padding-right: 4rem;
    }


    .home-header h2 {
        top: 19vh;
    }


    .home-nav {
        height: 6rem;
    }
    .home-nav ul li {
        padding: 0 1rem;
    }
    .home-nav ul li button {
        font-size: 24px;
    }

    .home-article {
        padding: 1rem 3rem 0;
    }
}
@media only screen and (min-width: 1023px) and (max-width: 1441px) {



    .home-nav {
        height: 3rem;
        width: 100%;
        overflow: hidden;
    }
    .home-nav ul {
        width: 100%;
    }

    .home-header {
        height: 55vh;

    }

    .home-header h1:nth-child(1n) {
        font-size: 48px;
        padding-right: 5rem;
        
    }
    .home-header h1:nth-child(2n) {
        padding-right: 7.8rem;
    }

    .home-header h1:nth-child(1n){
        height: 55vh;
    }

    .home-header h2 {
        top: 50vh;
        padding-right: 5rem;
    }
    .home-header .header-shadow-1{
        width: 50%; height: 55vh;
    }
    .home-header .header-shadow-2 {
        width: 100%; height: 10vh;
        top: 45vh;
        margin-left: auto;
        margin-right: auto;
    }


    .home-article {
        padding: 1rem 5rem 0;

    }
}

@media only screen and (min-width: 1441px) and (max-width: 1920px) {

    .home-header h1:nth-child(1n) {
        font-size: 65px;
        padding-right: 5rem;
        
    }
}