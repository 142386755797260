.contact-view {
    width: 100%; height: 100%;
    display: flex;
    flex-direction: column;
    color: white;
    text-align: center;
    padding-top: 2rem;
}

.contact-view .contact-info h2{
    font-size: 22px;
    font-weight: 400;
    margin-bottom: 1rem;
    
}

.contact-view .contact-social {
    display: flex;
    justify-content: center;
    width: 100%; height: 4rem;
}
.contact-view .contact-social img {
    max-width: 2rem; max-height: 2rem;
    margin: 0 0.5rem;
}

.contact-view .formik {
    width: 100%; height: 100%;
    padding: 1rem 0.5rem;
}
.contact-view .formik form {
    padding: 1rem;
    display: flex;
    flex-direction: column;
}
.contact-view .formik form input {
    height: 3rem;
    text-align: center;
    margin-bottom: 0.6rem;
}
.contact-view .formik form button{
    height: 3rem;
}


@media only screen and (min-width: 1023px) and (max-width: 1440px) {
    .contact-view .formik {
        width: 100%; height: 100%;
        padding: 1rem 25%;
    }
}