.viewer-img{
    position: fixed;
    margin: 0; padding: 0;
    top: 0; left: 0;
    width: 100%; height: 100vh;
    background-color: rgba(5, 5, 5, 0.938);
    z-index: 50;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 250;
}
.viewer-img .card-icon {
    color: white;
    position: absolute;
    z-index: 100;
    cursor: pointer;
    top: 90%;
    font-size: 2.5rem;
    width: 2rem; height: 2rem;
    border-radius: 4rem;
    background-color: black;
    padding: 0.5rem;
}

.viewer-img .carousel {
    width: 100%; height: 80%;
}
.viewer-img .carousel .carousel-inner {
    width: 100%; height: 100%;
}
.viewer-img .carousel .carousel-inner .carousel-item{
    height: 100%;
}
.viewer-img .carousel .carousel-inner .carousel-item {
    
    background-position: center;
    background-repeat: no-repeat;
    background-size:100%;
}


@media only screen and (min-width: 1023px) and (max-width: 1440px) {

    .viewer-img .carousel .carousel-inner .carousel-item {
    
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }
}
