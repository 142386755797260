.galery-view {
    width: 100%; min-height: 25rem;
}

.galery-view .galery-nav {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
}

.galery-view .galery-nav ul{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0; padding: 0;
}

.galery-view .galery-nav ul li {
    list-style: none;
}
.galery-view .galery-nav ul li button{
    background-color: inherit;
    border: none;
    color: rgba(194, 194, 194, 0.664);
}
.galery-view .galery-nav ul li .active {
    color: rgb(153, 255, 245);
}

.galery-view .video-conteiner {
    display: flex; flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}
.galery-view .video-conteiner .video-item {
    height: 100%;
    padding: 1rem;
    margin: 0 0.5rem;
    background-color: rgba(0, 0, 0, 0.24);
    border-radius: 0.5rem;

}
.galery-view .video-conteiner .video-item h2 {
    font-size: 16px;
    color: white;
    font-family: 'Poppins'; 
}

