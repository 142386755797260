
.track-item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%; height: 7rem;
    background-color: black;
    margin: 0.5rem 0;
    padding: 0.5;
    color: white;
    border-radius: 0.2rem;
}
.track-item .card-info {
    width: 50%; height: 100%;
    margin: 0 0.5rem;
    display: flex; flex-direction: column;
    justify-content: center;
}
.track-item .card-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

}
.track-item .card-info h2 {
    font-size: 18px;
    font-weight: 500;
}
.track-item .card-info h3 {
    font-size: 16px;
    font-weight: 100;
}
.track-item .card-icon-compact {
    padding: 0.5rem;
    
    font-size: 3rem;
    color: rgba(255, 255, 255, 0.733);
}
.selected {
    background-color: rgb(49, 49, 49);
}
.track-item .active {
    color: rgba(255, 112, 112, 0.733);
}
.track-item .card-icon-play {
    
    font-size: 3rem;
    margin: 0 0.5rem;
    color: white;
    border: 3px solid rgb(238, 98, 74);
    border-radius: 5rem;
    cursor: pointer;
}


@media only screen and (min-width: 765px) and (max-width: 1000px) {

}