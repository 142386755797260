/* Estilos minimalistas para la navegación de la galería */
.gallery-nav {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.gallery-nav ul {
    list-style-type: none;
    display: flex;
    gap: 15px;
    padding: 0;
    margin: 0;
}

.gallery-nav li {
    display: inline-block;
}

.gallery-nav button {
    background: none;
    color: inherit; /* Usa el color de la fuente existente */
    font-size: inherit; /* Mantiene la fuente actual */
    padding: 8px 16px;
    border: 1px solid transparent;
    border-radius: 4px;
    cursor: pointer;
    color: #b1b1b1;
    transition: border-color 0.3s ease, color 0.3s ease;
}

.gallery-nav button:hover {
    border-color: #888; /* Color sutil al pasar el ratón */
    color: #bebebe;
}

.gallery-nav button.active {
    border-color: #bebebe; /* Color para indicar el botón activo */
    color: #bebebe;
}

.gallery-nav button:focus {
    outline: none;
}

.video-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
}

.video-item {
    width: 100%;
    max-width: 800px; /* Tamaño máximo para que no se expanda demasiado */
    margin: 0 auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    overflow: hidden;

}

.video-item h2 {
    font-size: 1.5em;
    text-align: center;
    margin: 10px 0;
    color: #ffffff;
}

.video-item iframe {
    width: 100%;
    height: 450px;
    border: none;
    border-radius: 0 0 8px 8px;
}

@media (max-width: 768px) {
    .video-item iframe {
        height: 300px;
    }
    
    .video-item h2 {
        font-size: 1.2em;
    }
}
