

.gallery {
    width: 100%; 
    margin-bottom: 1rem;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
}

.gallery .gallery-img {
    width: 100%; height: 7.5rem;

    display: flex; align-items: center; justify-content: center;

    margin:0.1rem;
    background-size: cover;
    background-position: center;
    
    
    overflow: hidden;

    cursor: pointer;
    
    
}
.gallery .gallery-img .card-icon {
    color: rgb(219, 253, 255);
    font-size: 6rem;
    opacity: 12%;
 
}


@media only screen and (min-width: 1023px) and (max-width: 1440px) {

    .gallery {
        display: flex;
        flex-direction: row;
    }
    .gallery .gallery-img {
        width: 40%;
        margin: 0 0.5rem 1rem;
    }
}